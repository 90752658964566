<template>
  <div v-loading="loading">
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>课堂</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/checkCourse' }"
          >打卡课程</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加打卡</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      style="padding: 48px 72px"
      label-position="right"
      label-width="auto"
    >
      <el-form-item label="课程名称: " prop="name">
        <el-input
          v-model="form.name"
          style="width: 240px"
          autofocus
          clearable
        />
      </el-form-item>

      <el-form-item label="是否上架: " prop="status">
        <el-switch v-model="form.status" />
      </el-form-item>

      <el-form-item label="解锁模式: ">
        <!-- <el-select v-model="form.unlock_type" placeholder="请选择">
          <el-option label="无" value="0"></el-option>
          <el-option label="顺序解锁" value="1"></el-option>
        </el-select> -->
        <el-radio v-model="form.unlock_type" :label="0">无</el-radio>
        <el-radio v-model="form.unlock_type" :label="1">顺序解锁</el-radio>
      </el-form-item>

      <el-form-item label="封面: ">
        <select-images
          @onSelect="onSelect"
          @onDelete="onDelete"
          :image="image"
        ></select-images>
      </el-form-item>
      <el-form-item label="是否包含服务: " prop="notification">
        <el-switch v-model="form.contain_service" />
      </el-form-item>


      <el-form-item label="是否通知: " prop="notification">
        <el-switch v-model="form.notification" />
      </el-form-item>

      <el-form-item label="开启点评: " prop="notification">
        <el-switch v-model="form.is_work" />
      </el-form-item>

      <el-form-item label="课程计划奖状: ">
        <select-poster
          @onSelect="onSelectPlan"
          @onDelete="onDeletePlan"
          :image="poster_templates_plan_pic"
        ></select-poster>
      </el-form-item>

      <el-form-item label="报名后几天更新: " prop="day">
        <el-input v-model="form.day" style="width: 240px" clearable />
      </el-form-item>

      <el-form-item label="课程奖状: ">
        <select-poster
          @onSelect="onSelectCourse"
          @onDelete="onDeleteCourse"
          :image="poster_templates_course_pic"
        ></select-poster>
      </el-form-item>

      <el-form-item label="简易模板: ">
        <el-radio v-model="form.simple_template" :label="0">是</el-radio>
        <el-radio v-model="form.simple_template" :label="1">否</el-radio>
      </el-form-item>

      <el-form-item label="更新周期: " prop="update_day">
        <el-checkbox-group v-model="form.update_day" size="small">
          <el-checkbox-button
            v-for="(city, index) in ['日', '一', '二', '三', '四', '五', '六']"
            :label="index"
            :key="index"
          >
            周{{ city }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="解答次数: ">
        <el-input-number
          v-model="form.answer_nums"
          :min="0"
          label="描述文字"
          size="mini"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="金额: " prop="amount">
        <el-input v-model="form.amount" style="width: 240px" clearable />
      </el-form-item>

      <el-form-item label="适合年级：" prop="grade_id">
        <el-cascader
          style="width: 200px"
          v-model="form.grade_id"
          :options="options"
          :props="{ expandTrigger: 'hover', multiple: true }"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="有效期: " prop="indate">
        <el-input v-model="form.indate" style="width: 240px" clearable />
      </el-form-item>

      <el-form-item label="内容：" prop="content">
        <editor @onChange="onChange" :content="form.content" />
      </el-form-item>

      <el-form-item>
        <el-button @click="$router.back()" size="mini">取消</el-button>
        <el-button type="primary" size="mini" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import editor from "@/components/editor";
import { config } from "@/js/config";
import SelectImages from "../../../components/selectImages/index";
import selectPoster from "@/components/selectPoster";

export default {
  components: { SelectImages, editor, selectPoster },

  data: () => ({
    config,
    loading: false,
    image: "",
    poster_templates_plan_pic: "",
    poster_templates_course_pic: "",
    rules: {
      day: [{ required: true }],
      name: [{ required: true }],
      status: [{ required: true }],
      pic: [{ required: true }],
      notification: [{ required: true }],
      amount: [{ required: true }],
      indate: [{ required: true }],
      grade_id: [{ required: false }],
      content: [{ required: true }],
      update_day: [{ required: true }],
    },

    options: [],
    form: {
      day: 0,
      name: "",
      pic: "",
      status: true,
      amount: 0,
      indate: 365,
      notification: true,
      grade_id: [],
      content: "",
      update_day: [1, 2, 4, 5],
      is_work: false,
      answer_nums: 0,
      unlock_type: 0,
      simple_template:0,
      contain_service:0,
    },
  }),

  methods: {
    ...mapActions("punch", ["addPunch", "getCheckDetail"]),

    ...mapActions("common", ["getBaseData"]),

    uploadSuccess(path) {
      this.form.pic = path;
    },

    onChange(content) {
      this.form.content = content;
    },
    onSelect(image) {
      this.image = imageUrl + image.path;
      this.form.pic = image.path;
    },
    onSelectPlan(row) {
      this.poster_templates_plan_pic = imageUrl + row.pic;
      this.form.poster_templates_plan_id = row.id;
      this.form.poster_templates_plan_pic = row.pic;
    },
    onDeletePlan() {
      this.poster_templates_plan_pic = "";
      this.form.poster_templates_plan_id = 0;
      this.form.poster_templates_plan_pic = "";
    },
    onSelectCourse(row) {
      this.poster_templates_course_pic = imageUrl + row.pic;
      this.form.poster_templates_course_id = row.id;
      this.form.poster_templates_course_pic = row.pic;
    },
    onDeleteCourse() {
      this.poster_templates_course_pic = "";
      this.form.poster_templates_course_id = 0;
      this.form.poster_templates_course_pic = "";
    },
    onDelete() {
      this.image = "";
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        else {
          this.loading = true;

          this.form.grade_id = this.form.grade_id.map((item) => item[1]);

          const result = await this.addPunch(this.form);

          this.loading = false;

          this.$message.success("添加成功");

          this.$router.push("/checkCourse");
        }
      });
    },
  },

  async created() {
    if (this.$route.query.id) {
      this.loading = true;

      const { data } = await this.getCheckDetail({ id: this.$route.query.id });

      this.form = data;
    }

    const {
      data: { level, grades },
    } = await this.getBaseData();

    level.forEach((level) => {
      let children = [];

      grades.forEach((grade) => {
        if (grade.level_id == level.id) {
          children.push({
            value: grade.id,
            label: grade.name,
          });
        }
      });

      this.options.push({
        value: level.id,
        label: level.name,
        children: children,
      });
    });

    const grade_ids = [];

    grades.forEach((grade) => {
      if (this.form.grade_id.find((item) => item == grade.id)) {
        grade_ids.push([grade.level_id, grade.id]);
      }
    });

    this.form.grade_id = grade_ids;

    this.loading = false;
  },
};
</script>
